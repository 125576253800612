<template>
  <div class="tw-flex tw-flex-col">
    <div class="height-45 s_flex_bian_c width100">
      <div class="height-45 s_flex_center width50 fz-14" :class='active == 1 ? "s_bf054 tw-text-white":"s_bf"' @click='active = 1'>扫码界面</div>
      <div class="height-45 s_flex_center width50 fz-14" :class='active == 2 ? "s_bf054 tw-text-white":"s_bf"' @click='getMore();active = 2'>商品详情</div>
      <div class="height-45 s_flex_center width50 fz-14" :class='active == 3 ? "s_bf054 tw-text-white":"s_bf"' @click='resetLoading();active = 3'>物流码明细</div>
    </div>
    <div class="m-t-10" v-if='active == 1'>
      <van-form @submit="onSubmit">
        <van-field v-model="form.refund_warehousing_sn" name="refund_warehousing_sn" label="退货单号" placeholder="退货单号" readonly required :rules="[{ required: true, message: '退货单号不能为空' }]">
          <template #button>
            <van-button color='#5B9DFF' size="small" type="primary" @click="orderShow = true">按单退货</van-button>
          </template>
        </van-field>
        <!-- <van-field v-model="form.warehouse_name" is-link  readonly name="warehouse_name" label="退货仓库" placeholder="请选择退货仓库" required :rules="[{ required: true, message: '请选择退货仓库' }]" @click="()=>{if(isDisabled){return} warehouseShow = true}"/> -->
        <van-field v-model="form.returnee" readonly name="returnee" label="退货人" placeholder="扫码成功后自动填写信息" />
        <van-field name="radio" label="扫码类型">
          <template #input>
            <van-radio-group v-model="form.type" direction="horizontal" checked-color='#5B9DFF' @click='typeCheck = true'>
              <van-radio :name="1">扫码退货</van-radio>
              <van-radio :name="2">扫码回退</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field v-model="form.code" name="code" label="二维码" ref="codeEle" placeholder="请输入二维码" autocomplete="off" required :rules="[{ required: true, message: '二维码不能为空' }]">
          <template #button>
            <van-button style="width:66px" block color='#5B9DFF' size="small" type="primary" native-type="submit">{{form.type == 1 ? '退货':'回退'}}</van-button>
          </template>
        </van-field>
        <div class="s_flex_bian_c s_wrap fz-14 p-15" v-if='showResult'>
          <p class="width50">退货物流码数量：{{scanCodeNum}}</p>
          <p class="width50">退货商品数量：{{successGoodsNum}}</p>
          <p class="width100 m-t-15"  v-if='!typeCheck'>
            <span v-if='form.type == 1'>扫码结果：{{resultCode == 0 ? '退货成功！':'退货失败！'}}</span>
            <span v-else>扫码结果：{{resultCode == 0 ? '回退成功！':'回退失败！'}}</span>
          </p>
          <p class="width100 m-t-15" v-if='resultCode != 0 && !typeCheck'>{{resultMsg}}</p>
        </div>
      </van-form>
    </div>
    <div v-if='active == 2'>
      <div class="p-10" v-if='goodsList.length'>
        <div class="s_bf p-10 m-b-10 tw-rounded-sm" v-for="(item,index) in goodsList" :key='index'>
          <div class="s_flex_bian_c">
            <img class="width-80 height-80 tw-rounded-sm" :src="info.oss_domain+item.product_image" alt="">
            <div class="width72">
              <p class="fz-15 s_omit2">{{item.product_name}}</p>
              <p class="tw-text-gray-400 m-t-5">规格：{{item.product_sku_name || '单规格'}}</p>
              <p class="tw-text-gray-400">编号：{{item.product_sn}}</p>
            </div>
          </div>
          <van-divider :style="{ margin: '10px 0' }"/>
          <p class="fz-14">商品数量：{{item.refund_warehousing_num}}</p>
        </div>
        <p class="tw-text-center tw-text-gray-400 fz-14 p-t-10 p-b-10">我也是有底线的~</p>
      </div>
      <van-empty v-else :image="require('@/assets/image/empty.png')" description="暂无商品" />
    </div>
    <div  v-if='active == 3' class="m-t-10 content tw-flex tw-flex-col tw-flex-1">
      <van-search
        v-model="search.keyword"
        show-action
        placeholder="请输入物流码进行搜索"
        @search="onRefresh"
      >
        <template #action>
          <div class="height-34 s_flex_center s_bf054 width-55 tw-text-white tw-rounded-sm" @click="onRefresh">搜索</div>
        </template>
      </van-search>
      <div class="tw-flex-1 p-15">
        <div class="s_flex_bian_c s_bf5 height-40 tw-border tw-border-gray-200 tw-border-solid tw-bg-gray-300 tw-bg-opacity-30" v-if='list.length'>
          <p class="width33 s_flex_center">物流码</p>
          <p class="width33 s_flex_center">码类型</p>
          <p class="width33 s_flex_center">商品数量</p>
        </div>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list v-model:loading="loading" :finished="finished" :finished-text="list.length?'没有更多了':''" @load="onLoad">
            <div class="s_flex_bian_c height-40 tw-border tw-border-gray-200 tw-border-solid tw-border-t-0 s_bf" v-for="(item,index) in list" :key='index'>
              <p class="tw-text-center width33">{{item.code_sn}}</p>
              <p class="tw-text-center width33">{{item.code_type_text}}</p>
              <p class="tw-text-center width33">{{item.code_num}}</p>
            </div>
          </van-list>
        </van-pull-refresh>
      <van-empty v-if='!list.length' :image="require('@/assets/image/empty.png')" description="暂无物流码" />
      </div>
    </div>
    <!-- 选择仓库 -->
    <van-popup v-model:show="warehouseShow" position="bottom" round safe-area-inset-bottom>
      <selectWarehouse :showChild='1' @cancel='warehouseShow = false' @confirm='warehouseConfirm'></selectWarehouse>
    </van-popup>
    <!-- 选择订单 -->
    <van-popup v-model:show="orderShow" position="bottom" round safe-area-inset-bottom closeable :style="{ maxHeight: '80%'}">
      <selectOrder orderTpye='return' @select='orderConfirm'></selectOrder>
    </van-popup>
    <audioPlay ref="audios" />
  </div>
</template>

<script>
  import { ref, onMounted, inject, reactive, toRefs } from "vue";
  import { Toast } from 'vant';
  import { iscode } from '@/utils/app.js';
	import { getRefundWarehousingCode,setRefundWarehouse,getRefundWarehouseSn,getRefundWarehouseMore } from '@/api/refundWarehouse.js';
  import {useList} from '@/hooks/useListHandle'
  import {useWare} from '@/hooks/function'
  import store from '@/store'
import { useWatchScan } from "@/hooks/scanWatch.js";
  let defForm = {
    refund_warehousing_sn: '',
    refund_warehouse_id: null,
    refund_warehouse_position_id: null,
    type:1,
    code:''
  };
  export default {
    setup() {
      const form = ref({ ...defForm });
      const state = reactive({
        active: 1,
        goodsList:[],
        warehouseShow:false, //是否显示仓库选择
        orderShow:false, //是否显示订单选择
        isDisabled:false, //是否禁用某些选项
        showResult:false, // 是否显示扫码结果
        scanCodeNum:0, //扫码数量
        successGoodsNum:0, //商品数量
        resultCode:'', //扫码结果code
        resultMsg:'', // 扫码结果提示语
        info:store.state.user.info,
        typeCheck:false, //扫码类型切换，暂时隐藏入库成功/回退成功
      })
      const { onRefresh, search,finished, refreshing, onLoad, list, loading,resetLoading } = useList(getRefundWarehousingCode,{keyword: "",limit: 10,page: 1});
      // 获取单号
      const getSn = async() =>{
        let result = await getRefundWarehouseSn().then(res => res.data).catch(error => error)
        if (iscode(result)) {
          form.value.refund_warehousing_sn = result.data.refund_warehousing_sn;
          search.value.refund_sn = result.data.refund_warehousing_sn
				}else{
          Toast.fail(result.msg);
				}
      }
      // 获取详情
      const getMore = async() =>{
        if(!state.isDisabled){
          return
        }
        let result = await getRefundWarehouseMore(form.value.refund_warehousing_sn).then(res => res.data).catch(error => error)
        if (iscode(result)) {
          state.goodsList = result.data.product;
				}else{
          Toast.fail(result.msg);
				}
      }
      // 仓库选择
      const warehouseConfirm = (e) =>{
        form.value.refund_warehouse_id = e[0].id;
        form.value.refund_warehouse_position_id = e[1] ? e[1].id : 0;
        form.value.warehouse_name = e[0].name+(e[1] ? '/'+e[1].name : '');
        state.warehouseShow = false
      }
      // 订单选择
      const orderConfirm = (e) =>{
        form.value.refund_warehousing_sn = e.refund_warehousing_sn;
        search.value.refund_sn = e.refund_warehousing_sn;
        form.value.refund_warehouse_id = e.refund_warehouse_id;
        form.value.refund_warehouse_position_id = e.refund_warehouse_position_id;
        form.value.returnee = e.create_role_name
        form.value.warehouse_name = e.refund_warehouse_name+(e.refund_warehouse_position_name ? '/'+e.refund_warehouse_position_name : ''); //仓库名称
        state.orderShow = false;
        state.isDisabled = true;
        state.showResult = false; // 是否显示扫码结果
        state.scanCodeNum = 0; //扫码数量
        state.successGoodsNum = 0; //商品数量
        state.resultCode = ''; //扫码结果code
        state.resultMsg = ''; // 扫码结果提示语
      }
      const audios=ref('')
       const loadingShipment=ref(false)
       const codeEle=ref('')
      const onSubmit = async(values) => {
        if(loadingShipment.value){
          return
        }
        loadingShipment.value=true

        if(!form.value.code){
          Toast.fail('请输入二维码');
          return
        }
        codeEle.value.blur()
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
          duration:0
        });
        let result = await setRefundWarehouse({ ...form.value}).then(res => res.data).catch(error => error)
        Toast.clear();
        loadingShipment.value=false
        if (iscode(result)) {
           audios.value.pass()
          Toast.success(result.msg);
          state.scanCodeNum = state.scanCodeNum+1
          state.isDisabled = true
          form.value.returnee = result.data.name
          if(form.value.type == 1){
            state.successGoodsNum = state.successGoodsNum+result.data.num
          }else{
            state.successGoodsNum = state.successGoodsNum-result.data.num
          }
				}else{
          form.value.code=''
          Toast.fail(result.msg);
          audios.value.error()
				}
        state.resultCode = result.code
        state.resultMsg = result.msg
        state.showResult = true
        form.value.code = ''
        state.typeCheck = false
        codeEle.value.focus()
      };
      onMounted(async() => {
        getSn();
                     // 设置默认仓库
       let ware= await useWare().getWareList()
        form.value.refund_warehouse_id = ware.data[0].id;
        form.value.position_id = 0;
        form.value.warehouse_name = ware.data[0].name
      })
          useWatchScan().openWatch((status) => {
      if (!status && !form.value.code) {
        codeEle.value.focus();
      }
    });
      return {
        ...toRefs(state),
        form,
        onSubmit,
        warehouseConfirm,
        orderConfirm,
        onRefresh,
        finished,
        loading,
        refreshing,
        list,
        search,
        onLoad,
        getMore,
        resetLoading,
        audios,
        codeEle
      };
    }
  };
</script>
